export const companyImages = [
  {
    src: "/welcome-slides/1.webp",
    thumb: "/welcome-slides/thumb-1.webp"
  },
  {
    src: "/welcome-slides/2.webp",
    thumb: "/welcome-slides/thumb-2.webp"
  },
  {
    src: "/welcome-slides/3.webp",
    thumb: "/welcome-slides/thumb-3.webp"
  },
  {
    src: "/welcome-slides/4.webp",
    thumb: "/welcome-slides/thumb-1.webp"
  },
  {
    src: "/welcome-slides/5.webp",
    thumb: "/welcome-slides/thumb-2.webp"
  },
  {
    src: "/welcome-slides/6.webp",
    thumb: "/welcome-slides/thumb-3.webp"
  },
  {
    src: "/welcome-slides/7.webp",
    thumb: "/welcome-slides/thumb-1.webp"
  },
  {
    src: "/welcome-slides/8.webp",
    thumb: "/welcome-slides/thumb-2.webp"
  },
  {
    src: "/welcome-slides/9.webp",
    thumb: "/welcome-slides/thumb-3.webp"
  },
  {
    src: "/welcome-slides/10.webp",
    thumb: "/welcome-slides/thumb-1.webp"
  },
  {
    src: "/welcome-slides/11.webp",
    thumb: "/welcome-slides/thumb-2.webp"
  }
]

export const venkiImages = [
  {
    src: '/venki/slider/1.webp',
    thumb: '/venki/slider/1_thumb.jpg'
  },
  {
    src: '/venki/slider/2.webp',
    thumb: '/venki/slider/2_thumb.jpg'
  },
  {
    src: '/venki/slider/3.webp',
    thumb: '/venki/slider/3_thumb.jpg'
  },
  {
    src: '/venki/slider/4.webp',
    thumb: '/venki/slider/4_thumb.jpg'
  },
]