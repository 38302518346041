import dynamic from 'next/dynamic'

import CustomLink from '@/components/shared/CustomLink'
import BlockReviews from '@/sections/BlockReviews'
const TabsDynamic = dynamic(() => import('@/sections/Tabs'))
import { companyImages } from '@/sections/WelcomeSlider/slides'
const WelcomeSlider = dynamic(() => import('@/sections/WelcomeSlider'))

import Head from 'next/head'
import Link from 'next/link'
import { NextSeo } from 'next-seo'

export default function Index() {

  return (
    <div>
      <Head>
        <title>ООО “Ритуальная служба” - ритуальные услуги г. Красноярск</title>
        <meta name="description" content="Красноярская ритуальная служба предоставляет ритуальные услуги для жителей Красноярска и Красноярского края - организация похорон и кремации" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <NextSeo
        openGraph={{
          type: 'website',
          url: 'https://ritualservice24.ru',
          title: 'ООО “Ритуальная служба” - ритуальные услуги г. Красноярск',
          description: 'Красноярская ритуальная служба предоставляет ритуальные услуги для жителей Красноярска и Красноярского края - организация похорон и кремации',
          images: [
            {
              url: 'https://ritualservice24.ru/welcome-slides/1.webp',
              width: 800,
              height: 600,
              alt: 'Внутреннее помещение',
            },
            {
              url: 'https://ritualservice24.ru/welcome-slides/2.webp',
              width: 800,
              height: 600,
              alt: 'Здание снаружи',
            },
            {
              url: 'https://ritualservice24.ru/welcome-slides/3.webp',
              width: 800,
              height: 600,
              alt: 'Столы',
            },
          ],
        }}
      />

      <div className='custom-welcome-slider'>
        <WelcomeSlider customClassNames="without-border" slides={companyImages} />
      </div>
      <BlockReviews />
      <TabsDynamic />
      <div className="welcome-info">
        <div className="welcome-info__container">
          <div className="billet billet_2 welcome-info__main-content">
            <div className="welcome-info__item">
              <CustomLink href="/" passHref><a className="welcome-info__title">Планирование похорон</a></CustomLink>
              <p className="welcome-info__description">
                Непосредственное столкновение со смертью близкого человека приводит в состояние
                тяжёлого эмоционального шока. Тем более важно понимать точно,
                <CustomLink href="/" passHref><a>что делать</a></CustomLink>,
                если умер человек. Своевременно предпринятые верные шаги обеспечат достойные
                похороны без вмешательства недобросовестных агенств ритуального бизнеса.
              </p>
            </div>

            <div className="welcome-info__item">
              <Link passHref href="/"><a className="welcome-info__title">Контроль качества и цен</a></Link>
              <p className="welcome-info__description">
                Любое заказанное у нас траурное мероприятие будет организовано качественно,
                с трепетным вниманием к усопшему и его близким вне зависимости от общей стоимости.
                Включены все услуги, никаких доплат в процессе похорон.
                С образцом договора можно ознакомиться <CustomLink href="/" passHref><a>здесь</a></CustomLink>.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="welcome-info-alt">
        <div className="welcome-info-alt__container">
          <div className="billet billet_3 welcome-info-alt__main-content">
            <div className="welcome-info-alt__item">
              <CustomLink href="/" passHref><a className="welcome-info-alt__title">Круглосуточная консультация</a></CustomLink>
              <p className="welcome-info-alt__description">
                Если вам необходимы наши услуги <br /> <a href="tel:89029269703">звоните</a> 24 часа в сутки
              </p>
            </div>

            <div className="welcome-info-alt__item">
              <CustomLink href="/" passHref><a className="welcome-info-alt__title">Что нужно знать</a></CustomLink>
              <p className="welcome-info-alt__description">
                Мы подготовили <CustomLink href="/" passHref><a>информацию</a></CustomLink>, которая
                может быть вам полезна
              </p>
            </div>

            <div className="welcome-info-alt__item">
              <CustomLink href="/" passHref><a className="welcome-info-alt__title">Выезд агента</a></CustomLink>
              <p className="welcome-info-alt__description">
                Круглосуточно и всегда
                <CustomLink href="/" passHref><> <a>бесплатно</a></></CustomLink>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="welcome-info-alt">
        <div className="welcome-info-alt__container">
          <div className="billet billet_4 welcome-info-alt__main-content">
          </div>
        </div>
      </div>

      <div className="welcome-info-alt">
        <div className="welcome-info-alt__container">
          <div className="billet billet_5 welcome-info-alt__main-content">
          </div>
        </div>
      </div>


    </div>
  )
}
