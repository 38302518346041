import styled from "@emotion/styled";

const Main = styled.div`
`;

const Wrapper = styled.div`
  border-bottom: 1px solid #d2c8a0;
  border-top: 1px solid #d2c8a0;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  min-height: 150px;
  margin: 30px 15px 30px 15px;
`;

export default function BlockReviews() {
  return (
    <Main>
      <Wrapper />
    </Main>
  )
}
